import { getLoginOptions } from "@/constants/endpoints/user";
import { _axios } from "../axios";
import { setCookieValue } from "@/utils/cookies";
import { TOKEN } from "@/constants/cookies";
import { ILoginData } from "./interfaces";

export const login = async (user: ILoginData) => {
  try {
    const { user_id, start_param, username, is_premium } = user;
    const options = getLoginOptions(
      user_id?.toString(),
      username,
      is_premium,
      start_param
    );

    const response = await _axios(options);
    const { token } = response.data;
    setCookieValue(TOKEN, token);
  } catch (error) {
    console.error("Error authenticating user", error);
  }
};
