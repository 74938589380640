export const isLocal = process.env.NODE_ENV === "development";

const mockTelegramUser = {
  id: "15215511512112",
  username: "user_local3",
  url: "https://avatars.mds.yandex.net/i?id=9f3478544faee59cb96218928386b478f3c16327-9880043-images-thumbs&n=13",
  start_param: "15215511ё15",
  is_premium:false,
  chat_id:"123123"
};

export const localConfig = {
  isLocal,
  user: isLocal ? mockTelegramUser : undefined,
  apiUrl: process.env.API_URL,
  wsUrl: process.env.WS_URL,
};
