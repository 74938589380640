import coin from "@/assets/buttons/coin.png";
import coin_revresh from "@/assets/buttons/coin_revresh.png";
import upgrade from "@/assets/buttons/upgrade.png";

export const ACTIONS_BULDINGS = [
  {
    id: 2,
    image: coin_revresh,
    directory: "auto_gathering",
    count: 35,
    height: 48,
    width: 48,
    margin: "0px 0px 0px 0px",
  },
  {
    id: 1,
    image: coin,
    count: 32,
    directory: "gathering_coin",
    height: 96,
    width: 48,
    margin: "0px 0px 0px 0px",
  },
  {
    id: 3,
    image: upgrade,
    count: 22,
    directory: "upgrade",
    height: 72,
    width: 48,
    margin: "-30px 0px 0px 0px",
  },
];

// options for correct animation
// count - count images in anim
// directory - name of folder with images
// margin - margin between images
// width - width of image
// height - height of image
