import Image from "next/image";
import innerLoader from "@/assets/loader/loader_inner.png";
import head from "@/assets/icons/head_pirate.png";
import { useSelector } from "react-redux";
import { RootState } from "@/store";

import styles from "./styles.module.scss";

export default function Loader() {
  const { progressLoading } = useSelector((state: RootState) => state.app);

  const progressWidth = `${progressLoading}%`; 
  const headPosition = `translateX(${progressLoading * 3}%)`;  

  return (
    <section className={styles.wrapper}>
      <h1 className={styles.loader__title}>ЗАГРУЗКА...</h1>
      <div className={styles.loader}>
        <div className={styles.loader__inner__wrapper}>
          <Image
            style={{ width: progressWidth }}
            className={styles.loader__inner}
            src={innerLoader}
            alt="loader - inner"
          />
        </div>
        <Image
          className={styles.loader__head}
          width={80}
          height={80}
          src={head}
          alt="loader - head"
          style={{ transform: headPosition }}
        />
      </div>
    </section>
  );
}
