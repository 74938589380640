import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppSlice, ImagesByDirectory, IOptions } from "./interfaces";
import { ACTIONS_BULDINGS } from "@/components/Containers/ActionsHouseContainer/actions.constants";
import { ACTIONS_WHARF } from "@/components/Containers/ActionsWharfContainer/actions.constants";
import { RootState } from "..";
import { Language, LANGUAGES } from "@/constants/types";

const initialState: IAppSlice = {
  appRef: null,
  canvasRef: null,
  isLoading: false,
  language: LANGUAGES.ru,
  webApp: null,
  coinContainerRef: null,
  isSoundDisabled: false,
  progressLoading: 30,
  houses: {
    animations: {
      allImages: {},
      options: [...ACTIONS_BULDINGS, ...ACTIONS_WHARF],
    },
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setApp: (state, action) => {
      state.appRef = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setWebApp: (state, action) => {
      state.webApp = action.payload;
    },
    setCanvas: (state, action) => {
      state.canvasRef = action.payload;
    },
    setImages: (state, action) => {
      state.houses.animations.allImages = action.payload;
    },
    handleSound: (state, action) => {
      state.isSoundDisabled = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProgressLoading: (state, action) => {
      state.progressLoading = action.payload;
    },
  },
});

export const selectImages = (state: { app: IAppSlice }): ImagesByDirectory =>
  state.app.houses.animations.allImages;

export const selectOptions = (state: { app: IAppSlice }) =>
  state.app.houses.animations.options;

export const selectOptionsByDirectory = createSelector(
  [
    (state: RootState) => state.app.houses.animations.options,
    (_, directory: string) => directory,
  ],
  (options, directory) => {
    return options.find((option: IOptions) => option.directory === directory);
  }
);

export const selectImagesByDirectory = createSelector(
  [selectImages, (state, directory: string) => directory],
  (images, directory) => {
    return images[directory] || [];
  }
);

export const {
  setApp,
  handleSound,
  setIsLoading,
  setProgressLoading,
  setCanvas,
  setImages,
  setWebApp,
  setLanguage
} = appSlice.actions;
export default appSlice.reducer;
