import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ILeader, ILeaderboardState } from "./interface";
import { LEAGUES } from "@/constants";
import { LEADERS } from "./leaderboard.mockData";

const initialState: ILeaderboardState = {
  leaders: null,
  league: LEAGUES[2],
  page: 1,
  count: 0,
  next: "",
  size: 100,
  previous: "",
};

export const leaderboardSLice = createSlice({
  name: "leaderboard",
  initialState,
  reducers: {
    setStateLeaderboard: (state, action) => {
      if (action.payload.results.length < 3) {
        state.leaders = LEADERS;
        state.count = action.payload.count;
        state.next = action.payload.next;
        state.previous = action.payload.previous;
        return;
      }
      state.leaders = action.payload.results;
      state.count = action.payload.count;
      state.next = action.payload.next;
      state.previous = action.payload.previous;
    },
    setLeaders: (state, action) => {
      state.leaders = action.payload;
    },
    setLeague: (state, action) => {
      state.league = action.payload;
    },
    decreasePage: (state) => {
      state.page -= 1;
    },
    increasePage: (state) => {
      state.page += 1;
    },
  },
});

export const selectLeaders = (state: {
  leaderboard: ILeaderboardState;
}): ILeader[] => {
  if (!state.leaderboard.leaders) return [];
  return state.leaderboard.leaders;
};

export const selectWinners = createSelector([selectLeaders], (leaders) => {
  const winners = leaders.slice(1, 4);
  const reorderedWinners = [winners[1], winners[0], winners[2]];
  return reorderedWinners;
});

export const selectNominators = createSelector([selectLeaders], (leaders) => {
  return leaders.slice(4, leaders.length);
});

export const selectPerson = createSelector([selectLeaders], (leaders) => {
  const person = leaders[0];

  const sortedLeaders = [...leaders].sort((a, b) => {
    if (b.score === a.score) {
      if (typeof a.id === "number" && typeof b.id === "number") {
        return a.id - b.id;
      }
      return 0;
    }
    return b.score - a.score;
  });

  if (!sortedLeaders.some((nominator) => nominator.id === person.id)) {
    return {
      person,
      position: "100+",
      isPersonInNominators: false,
    };
  }

  const position = (
    sortedLeaders.findIndex((leader) => leader.id === person.id) + 1
  ).toString();

  return {
    person,
    position,
    isPersonInNominators: true,
  };
});

export const {
  setLeaders,
  decreasePage,
  increasePage,
  setLeague,
  setStateLeaderboard,
} = leaderboardSLice.actions;
export default leaderboardSLice.reducer;
