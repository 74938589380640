import { AnimatedSprite, Container, Stage } from "@pixi/react";
import { ISpriteProps } from "./interfaces";
import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { SPEED } from "./sprite.constants";
import ship_00 from "@/assets/animations/ship/ship_00.png";
import * as PIXI from 'pixi.js'


const Sprite: React.FC<ISpriteProps> = ({ images, options, onEnd }) => {
  const { width, height, margin, speed = 1 } = options;
  const [isPlaying, setIsPlaying] = useState(false);
  const [sprites, setSprites] = useState<string[]>([ship_00.src]);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (images && images.length > 0) {
      setSprites(images.filter((img) => img).sort());
      setIsPlaying(true);
      setVisible(true);
    } else {
      setIsPlaying(false);
      setSprites([ship_00.src]);
    }

    return () => {
      setIsPlaying(false);
      setSprites([]);
    };
  }, []);

  const handleAnimationComplete = (spriteNum: number) => {
    if (spriteNum >= sprites.length - 2) {
      setVisible(false);
      setIsPlaying(false);
      if (onEnd) {
        onEnd();
      }
    }
  };

  return (
    <>
      {isPlaying && visible && sprites.length > 0 && (
        <Stage
          className={styles.wrapper}
          width={width}
          height={height}
          options={{ backgroundAlpha: 0 }}
          style={{
            overflow: "hidden",
            margin: margin,
            zIndex: 20,
            backgroundColor: "transparent",
          }}
        >
          <Container>
            <AnimatedSprite
              isPlaying={isPlaying}
              key={sprites.join("-")}
              onFrameChange={handleAnimationComplete}
              initialFrame={0}
              visible={visible && isPlaying && sprites.length > 0}
              width={width}
              loop={false}
              height={height}
              animationSpeed={SPEED * speed}
              images={sprites}
            />
          </Container>
        </Stage>
      )}
    </>
  );
};

export default Sprite;
