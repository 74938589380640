import { Language, Leagues } from "./types";

export const LEAGUES: Leagues[] = ["Bronze", "Silver", "Gold"];

export const ROUTES = {
  LEADERBOARD: { url: "/leaderboard", title: "Лидерборд" },
  SHOP: { url: "/shop", title: "Магазин" },
  RULES: { url: "friends/rules", title: "Правила" },
  FRIENDS: { url: "/friends", title: "Друзья" },
  PROFILE: { url: "/profile", title: "Профиль" },
  SETTINGS: { url: "/settings", title: "Настройки" },
};

