import scroll from "@/assets/buttons/scroll.png";
import coin_revresh from "@/assets/buttons/coin_revresh.png";


export const ACTIONS_WHARF = [
  {
    id: 2,
    image: coin_revresh,
    directory: "auto_gathering",
    count: 34,
    height: 48,
    width: 48,
    margin: "0px 0px -40px 0px",
  },
  {
    id: 1,
    image: scroll,
    directory: "departure_ship",
    count: 38,
    height: 48,
    width: 48,
    margin:'0px 0px 40px 0px'
  },
];
