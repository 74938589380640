import { IActionButtonProps } from "./interface";


import styles from "./styles.module.scss";

export default function ActionButton({
  children,
  isDisabled,
  onClick,
}: IActionButtonProps) {
  return (
    <button className={styles.button_action} disabled={isDisabled} onClick={onClick}>
      {children}
    </button>
  );
}
