import { ILeader } from "./interface";

export const LEADERS: ILeader[] = [
  { id: 25, position: 1, name: "Имя Имя", score: 95 },
  { id: 0, position: 1, name: "Имя Имя", score: 9999 },
  { id: 1, position: 2, name: "Имя Имя", score: 999 },
  { id: 2, position: 3, name: "Имя Имя", score: 950 },
  { id: 3, position: 4, name: "Имя Имя", score: 901 },
  { id: 5, position: 5, name: "Имя Имя", score: 107 },
  { id: 6, position: 6, name: "Имя Имя", score: 106 },
  { id: 7, position: 7, name: "Имя Имя", score: 105 },
  { id: 8, position: 8, name: "Имя Имя", score: 104 },
  { id: 9, position: 9, name: "Имя Имя", score: 103 },
  { id: 10, position: 10, name: "Имя Имя", score: 102 },
  { id: 11, position: 11, name: "Имя Имя", score: 102 },
  { id: 12, position: 12, name: "Имя имя", score: 101 },
];
