import { ACTIONS_BULDINGS } from "@/components/Containers/ActionsHouseContainer/actions.constants";
import { ACTIONS_WHARF } from "@/components/Containers/ActionsWharfContainer/actions.constants";

async function loadImages(directory: string, count: number): Promise<string[]> {
  const images = [];
  for (let i = 0; i < count; i++) {
    try {
      const path = require(`@/assets/animations/${directory}/${directory}_${String(
        i
      ).padStart(2, "0")}.png`);
      images.push(path.default.src || path);
    } catch (error) {
      console.error(`Failed to load image ${directory}_${i}:`, error);
    }
  }
  return images;
}

function preloadImages(array: string[]) {
  if (!(preloadImages as any).list) {
    (preloadImages as any).list = [];
  }
  const list: HTMLImageElement[] = (preloadImages as any).list;

  for (let i = 0; i < array.length; i++) {
    const img = new Image();
    img.onload = () => {
      const index = list.indexOf(img);
      if (index !== -1) {
        list.splice(index, 1);
      }
    };
    list.push(img);
    img.src = array[i];
  }
}

export async function preloadAllImages() {
  const directories = [
    ...ACTIONS_WHARF.map((action) => action.directory),
    ...ACTIONS_BULDINGS.map((action) => action.directory),
    "ship",
  ];


  const uniqueDirectories: string[] = [];
  directories.forEach((directory) => {
    if (!uniqueDirectories.includes(directory)) {
      uniqueDirectories.push(directory);
    }
  });

  const imagesByDirectory: { [key: string]: string[] } = {};

  const loadPromises = uniqueDirectories.map(async (directory) => {
    let count = 0;

    for (const action of [...ACTIONS_WHARF, ...ACTIONS_BULDINGS]) {
      if (action.directory === directory) {
        count = action.count;
        break;
      }
    }

    if (directory === "ship") {
      count = 50;
    }

    const images = await loadImages(directory, count);
    imagesByDirectory[directory] = images;

  });

  await Promise.all(loadPromises);

  return imagesByDirectory;
}
