import { RootState } from "@/store";
import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

interface IUseClickSoundProps {
  src?: string;
}

export const SOUND = {
  CLICK: "click.mp3",
  SHIP: "ship.mp3",
  COLLECT: "collect.mp3",
  UPGRADE: "upgrade.mp3",
};

const useClickSound = ({ src }: IUseClickSoundProps = { src: SOUND.CLICK }) => { 
  const soundSrc = "/sounds/" + (src || SOUND.CLICK);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isSoundDisabled = useSelector(
    (state: RootState) => state.app.isSoundDisabled
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      audioRef.current = new Audio(soundSrc);
    }
  }, [soundSrc]);

  const handleClick = useCallback(() => {
    if (isSoundDisabled) return;
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      audioRef.current.play().catch((error) => {
        console.error("Error playing sound:", error);
      });
    }
  }, [isSoundDisabled]);

  return handleClick;
};

export default useClickSound;
