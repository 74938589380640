import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setWs } from "@/store/sockets/soketsSlice";
import { login } from "@/api/user";
import { getCookie } from "@/utils/cookies";
import { PRIZE, TOKEN } from "@/constants/cookies";
import { usePopup } from "@/hooks/usePopUp";
import { preloadAllImages } from "@/utils/loadImages";
import {
  setImages,
  setIsLoading,
  setLanguage,
  setProgressLoading,
  setWebApp,
} from "@/store/app/appSlice";
import { createWs, sendWsEvent } from "@/utils/ws";
import Loader from "@/components/Loader";
import { IAppInitializerProps } from "./interfaces";
import {
  ACTIONS,
  getPopUpDataByNames,
} from "@/utils/getters/getPopUpDataByName";
import {
  increaseStep,
  setActiveTutorial,
} from "@/store/tutorial/tutorialSlice";
import { getLocalStorageItem, setLocalStorageItem } from "@/utils/localStorage";
import Sprite from "@/components/Sprite";
import { ACTIONS_BULDINGS } from "@/components/Containers/ActionsHouseContainer/actions.constants";
import { ACTIONS_WHARF } from "@/components/Containers/ActionsWharfContainer/actions.constants";
import { SHIP_OTPIONS } from "@/components/Cards/ShipCard/shipcard.constant";
import { setUser } from "@/store/user/userSlice";
import getTelegramData from "@/utils/telegram/getTelegramData";
import { useRouter } from "next/router";
import { isLocal } from "../../../localConfig";
import { LANGUAGE } from "@/utils/location";
import { WebSocketsClientEvents } from "@/constants/events";

const wsUrl = `${process.env.WS_URL}`;

export default function AppInitializer({ children }: IAppInitializerProps) {
  const { isLoading } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();
  const router = useRouter();
  const { ws } = useSelector((state: RootState) => state.sockets);
  const { modal } = usePopup();
  const options = [...ACTIONS_BULDINGS, ...ACTIONS_WHARF, SHIP_OTPIONS];
  const images = useSelector(
    (state: RootState) => state.app.houses.animations.allImages
  );

  const setup = useCallback(async () => {
    if (ws !== null) return;
    try {
      dispatch(setIsLoading(true));
      const { user, webApp } = await getTelegramData();
      if (!webApp && !isLocal) {
        const currentPath = router.asPath;
        dispatch(setIsLoading(false));
        if (currentPath !== "/not-supported") {
          router.replace("/not-supported");
        }
        dispatch(setWs(null));
        return;
      }

      dispatch(setLanguage(webApp?.initDataUnsafe.user?.language_code));
      setLocalStorageItem(LANGUAGE, webApp?.initDataUnsafe.user?.language_code);
      dispatch(setWebApp(webApp));
      dispatch(setUser(user));
      if (user?.user_id) {
        await login(user).then(() => {
          const token = getCookie(TOKEN);
          const url = `${wsUrl}?token=${token}`;
          const _ws = createWs(url, modal, dispatch);
          dispatch(setWs(_ws));
        });
      }
      await loadData();
      setTimeout(() => {
        dispatch(setIsLoading(false));
      }, 550);
    } catch (error) {
      console.error("Ошибка инициализации приложения:", error);
    }
  }, [ws]);

  const loadData = async () => {
    dispatch(setProgressLoading(50));
    const images = await preloadAllImages();
    dispatch(setImages(images));
    dispatch(setProgressLoading(70));
  };

  useEffect(() => {
    if (router.pathname === "/not-supported") return;

    setup();

    return () => {
      if (ws) {
        ws.close();
        dispatch(setWs(null));
      }
    };
  }, []);

  return (
    <>
      {isLoading && (
        <>
          <Loader />
          {options.map((option) => {
            const image = images[option.directory];
            if (!images) return null;
            return <Sprite images={image} options={option} onEnd={() => {}} />;
          })}
        </>
      )}
      {!isLoading && <>{children}</>}
    </>
  );
}
