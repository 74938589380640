import { AxiosRequestConfig } from "axios";

export const URL_LOGIN = "/auth/signin";

export const getLoginOptions = (
  user_id: string,
  username: string,
  is_premium: boolean,
  referral_id?: string | null
): AxiosRequestConfig => ({
  url: URL_LOGIN,
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    user_id: user_id,
    referral_id: referral_id,
    username: username,
    is_premium: is_premium,
  },
});
