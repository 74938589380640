import { isBrowser } from "@/utils/telegram/isBrowser";
import { isLocal, localConfig } from "../../../localConfig";

let mockUserId = 222222;

const getTelegramData = async () => {
  try {
    if (!isLocal) {
      const WebApp = (await import("@twa-dev/sdk")).default;
      if (isBrowser() && WebApp) {
        
        WebApp.expand();
        const { user, chat } = WebApp.initDataUnsafe;
        
        if (!user) return { user_id: null };

        const user_id =
          String(user.id) || String(chat?.id) || String(mockUserId);
        mockUserId++;

        return {
          user: {
            user_id: user_id,
            username: user.username || user.first_name || String(user_id),
            is_premium: user.is_premium || false,
            start_param: WebApp.initDataUnsafe.start_param || null,
          },
          webApp: WebApp,
        };
      }
    } else {
      const user = localConfig.user;
      if (user) {
        return {
          user: {
            user_id: user.id,
            username: user.username,
            is_premium: user.is_premium,
            start_param: user.start_param || null,
          },
          webApp: null,
        };
      }
    }
  } catch (error) {
    console.error("Failed to get Telegram data:", error);
    return { user_id: null };
  }

  return { user_id: null };
};

export default getTelegramData;
