import { TUTORIALS } from "@/constants/localStorage";
import { ITutorialData } from "./interfaces";

export const TEXTS = [
  {
    title: "",
    text: "",
    isNeedButton: false,
  }, // 0
  {
    title: "",
    text: "",
    isNeedButton: false,
  }, // 1
  {
    title: "Добро пожаловать на ваш остров капитан!",
    text: "Стройте и улучшайте здания чтобы получать больше прибыли",
    isNeedButton: true,
  }, // 2
  {
    title: "Постройте здание",
    text: "Нажмите на постройку для взаимодействия с ней",
    isNeedButton: false,
  }, // 3
  {
    title: "Постройте здание",
    text: "Нажмите кнопку построить",
    isNeedButton: false,
  }, // 4
  {
    title: "Поздравляем, вы построили своё первое здание!",
    text: "Оно уже начало зарабатывать вам золото! Каждые 3 часа вам нужно забрать заработанное  золото, а то здание не будет больше зарабатывать",
    isNeedButton: true,
  }, // 5
  {
    title: "Заберите собранное золото!",
    text: "Каждые 3 часа здание полностью забивается золотом, не забывайте забирать его",
    isNeedButton: false,
  }, // 6
  {
    title: "Здания можно улучшить",
    text: "Нажмите на кнопку Улучшить, чтобы узнать сколько ресурсов необходимо для улучшения здания",
    isNeedButton: false,
  }, // 7
  {
    title: "Порт",
    text: "Вы можете отправлять корабль на добычу золота и жемчуга",
    isNeedButton: false,
  }, // 8
  {
    title: "Порт. Постройте здание",
    text: "Для начала, давайте построим здание Порт",
    isNeedButton: false,
  }, // 9
  {
    title: "Путешествие",
    text: "Давайте отправим корабль в путешествие, нажмите на свиток",
    isNeedButton: false,
  }, // 10
  {
    title: "Удачного путешествия капитан!",
    text: "Развивайте базу, чтобы получить больше золота и жемчуга",
    isNeedButton: true,
  }, // 11
];

export const TUTORIALS_OTHERS_PAGE: ITutorialData[] = [
  {
    name: TUTORIALS.SHOP,
    refs: [{ name: "container_shop", ref: null }],
    texts: [
      {
        title: "",
        text: "",
        isNeedButton: false,
      },
      {
        title: "Бустеры",
        text: "Это бустер Множитель, при использовании он увеличивает добычу золота на указанный множитель на определённое время. ",
        text2:
          "Важно знать! Одновременно может работать только один множитель, если будет использован любой другой, то он увеличит таймер текущего!",
        isNeedButton: true,
      },
      {
        title: "Бустеры",
        text: "Это бустер автосбор, он автоматически собирает 2 раза добытое золото с зданий и корабля. ",
        text2: "Активация бустера происходит при взаимодействии с зданием!",
        isNeedButton: true,
      },
      {
        title: "",
        text: "",
        text2: "",
        isNeedButton: false,
      },
    ],
    isActive: false,
    step: 0,
  },
  {
    name: TUTORIALS.FRIENDS,
    refs: [],
    texts: [
      {
        title: "Приглашай друзей и зарабатывай Тон!",
        text: "",
        isNeedButton: true,
      },
      {
        title: "Приглашай друзей и зарабатывай Тон!",
        text: "",
        isNeedButton: true,
      },
    ],
    isActive: false,
    step: 0,
  },
  {
    name: TUTORIALS.CHESTS,
    refs: [{ name: "container_chests", ref: null }],
    texts: [
      {
        title: "Приглашай друзей и зарабатывай Тон!",
        text: "",
        isNeedButton: true,
      },
      {
        title: "Откройте сундук и получите бонус “множитель”!",
        text: "",
        text2:
          "Данный бонус увеличивает добычу золота. Помните что если на вас есть активный бонус, то новый продлит его действие а не заменит!",
        isNeedButton: false,
      },
      {
        title: "",
        text: "",
        text2: "",
        isNeedButton: false,
      },
    ],
    isActive: false,
    step: 0,
  },
];
