import { createSlice } from "@reduxjs/toolkit";
import { IShopSlice } from "./interfaces";

const initialState: IShopSlice = { currentPage: "БУСТЕРЫ", shopItems: [] };

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setShopItems: (state, action) => {
      state.shopItems = action.payload;
    },
  },
});


export const { setCurrentPage, setShopItems } = shopSlice.actions;
export default shopSlice.reducer;
