import React, { useEffect, useRef } from "react";
import { sendWsEvent } from "@/utils/ws";
import { WebSocketsClientEvents } from "@/constants/events";
import {
  ACTIONS,
  getPopUpDataByNames,
} from "@/utils/getters/getPopUpDataByName";
import ton from "@/assets/icons/ton.png";
import { HOUSES } from "@/store/island/island.mockData";
import { TUTORIALS_OTHERS_PAGE } from "@/store/tutorial/tutorial.constants";
import Image from "next/image";
import Button from "@/components/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { setLocalStorageItem } from "@/utils/localStorage";
import { RootState } from "@/store";
import {
  getOptionsTutorialByName,
  increaseStep,
  increaseStepOther,
  setIsActive,
  setIsActiveOther,
  setZIndex,
  setZIndexOther,
} from "@/store/tutorial/tutorialSlice";
import { TUTORIALS } from "@/constants/localStorage";

import styles from "./styles.module.scss";
import useClickSound from "@/hooks/useClickSound";
import { useRouter } from "next/router";
import { PRIZE } from "@/constants/cookies";
import { usePopup } from "@/hooks/usePopUp";

const Tutorial = () => {
  const { activeTutorial } = useSelector((state: RootState) => state.tutorial);
  const { isActive, step, texts } = useSelector((state: RootState) =>
    getOptionsTutorialByName(state, activeTutorial)
  );
  const stepOther = useRef(step);
  const stepMain = useSelector((state: RootState) => state.tutorial.step);
  const stepMainRef = useRef(stepMain);
  const playSound = useClickSound();
  const { ws } = useSelector((state: RootState) => state.sockets);
  const currentText = texts[step];
  const dispatch = useDispatch();
  const { modal } = usePopup();

  useEffect(() => {
    stepMainRef.current = stepMain;
  }, [stepMain]);

  useEffect(() => {
    stepOther.current = step;
  }, [step]);

  console.log(step, activeTutorial);

  useEffect(() => {
    if (step == 1 && activeTutorial == TUTORIALS.MAIN) {
      const { title, textButton, text, icon, values } = getPopUpDataByNames(
        ACTIONS.PRIZE_FIRST_IN
      );

      modal(title, textButton, text, icon, values).then(() => {
        if (values) {
          const {
            values: { coins, pearls },
          } = values;
          sendWsEvent(ws, WebSocketsClientEvents.CHANGE_FIRST_EXP, {
            gold_reward: coins,
            pearl_reward: pearls,
          });
        }
        setLocalStorageItem(PRIZE, "true");
      });
    }

    if (step == 3 && activeTutorial == TUTORIALS.MAIN) {
      dispatch(setZIndex({ name: HOUSES[4].house_type_name, zIndex: "2500" }));
    }

    if (step == 5 && activeTutorial == TUTORIALS.MAIN) {
      dispatch(setZIndex({ name: HOUSES[4].house_type_name, zIndex: "50" }));
    }

    if (step == 6 && activeTutorial == TUTORIALS.MAIN) {
      dispatch(setZIndex({ name: HOUSES[4].house_type_name, zIndex: "2500" }));
    }

    if (step == 8 && activeTutorial == TUTORIALS.MAIN) {
      setTimeout(() => {
        dispatch(setZIndex({ name: HOUSES[4].house_type_name, zIndex: "50" }));
        dispatch(
          setZIndex({ name: HOUSES[0].house_type_name, zIndex: "2700" })
        );
        dispatch(setZIndex({ name: "ship", zIndex: "2500" }));
      }, 300);
    }

    if (step == 11 && activeTutorial == TUTORIALS.MAIN) {
      dispatch(setZIndex({ name: HOUSES[4].house_type_name, zIndex: "50" }));
      dispatch(setZIndex({ name: HOUSES[0].house_type_name, zIndex: "50" }));
      dispatch(setZIndex({ name: "ship", zIndex: "50" }));
    }

    if (step == 1 && activeTutorial == TUTORIALS.SHOP) {
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.SHOP,
          name: TUTORIALS_OTHERS_PAGE[0].refs[0].name,
          zIndex: "2500",
          cardNum: 1,
        })
      );
    }

    if (step == 2 && activeTutorial == TUTORIALS.SHOP) {
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.SHOP,
          name: TUTORIALS_OTHERS_PAGE[0].refs[0].name,
          zIndex: "1",
          cardNum: 1,
        })
      );
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.SHOP,
          name: TUTORIALS_OTHERS_PAGE[0].refs[0].name,
          zIndex: "2500",
          cardNum: 2,
        })
      );
    }

    if (activeTutorial == TUTORIALS.SHOP && step == 3) {
      dispatch(setIsActiveOther({ name: activeTutorial, isActive: false }));
      setLocalStorageItem(TUTORIALS.SHOP, true);
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.SHOP,
          name: TUTORIALS_OTHERS_PAGE[0].refs[0].name,
          zIndex: "1",
          cardNum: 1,
        })
      );
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.SHOP,
          name: TUTORIALS_OTHERS_PAGE[0].refs[0].name,
          zIndex: "1",
          cardNum: 2,
        })
      );
      return;
    }

    if (step == 2 && activeTutorial == TUTORIALS.CHESTS) {
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.CHESTS,
          name: TUTORIALS_OTHERS_PAGE[2].refs[0].name,
          zIndex: "none",
          cardNum: "chest_1",
        })
      );
      setLocalStorageItem(TUTORIALS.CHESTS, true);
      dispatch(setIsActiveOther({ name: activeTutorial, isActive: false }));
      return;
    }
  }, [step]);

  const handleClickNextStep = () => {
    playSound();
    if (step == 11 && activeTutorial == TUTORIALS.MAIN) {
      setLocalStorageItem(TUTORIALS.MAIN, true);
      dispatch(setIsActive(false));
      return;
    }

    if (activeTutorial == TUTORIALS.SHOP && step == 2) {
      dispatch(setIsActiveOther({ name: activeTutorial, isActive: false }));
      setLocalStorageItem(TUTORIALS.SHOP, true);
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.SHOP,
          name: TUTORIALS_OTHERS_PAGE[0].refs[0].name,
          zIndex: "none",
          cardNum: 1,
        })
      );
      dispatch(
        setZIndexOther({
          name_tutorial: TUTORIALS.SHOP,
          name: TUTORIALS_OTHERS_PAGE[0].refs[0].name,
          zIndex: "none",
          cardNum: 2,
        })
      );
      dispatch(increaseStepOther({ name: activeTutorial }));
      return;
    }

    if (step == 1 && activeTutorial == TUTORIALS.FRIENDS) {
      setLocalStorageItem(TUTORIALS.FRIENDS, true);
      dispatch(setIsActiveOther({ name: activeTutorial, isActive: false }));
      return;
    }

    if (activeTutorial != TUTORIALS.MAIN) {
      dispatch(increaseStepOther({ name: activeTutorial }));
    }

    dispatch(increaseStep());
  };

  const handleClickSkipTutorial = () => {
    if (activeTutorial == TUTORIALS.MAIN) {
      dispatch(setIsActive(false));
      return;
    }
    dispatch(setIsActiveOther({ name: activeTutorial, isActive: false }));
    setLocalStorageItem(activeTutorial, true);
  };

  const handleClickAnywhere = (event: any) => {
    if (
      event.target?.className == styles.tutorial__overlay ||
      event.target?.className == styles.tutorial__content
    ) {
      if (activeTutorial == TUTORIALS.MAIN && stepMainRef.current < 11) {
        handleChangeStepMain(stepMainRef.current);
        dispatch(increaseStep());
      }
      if (activeTutorial == TUTORIALS.MAIN && stepMainRef.current == 11) {
        dispatch(setIsActive(false));
      }
    }
  };

  const handleChangeStepMain = (step: number) => {
    const name_saloon = "saloon";
    const name_dock = "dock";
    switch (step) {
      case 4:
        sendWsEvent(ws, WebSocketsClientEvents.UPGRADE_HOUSE, {
          name: name_saloon,
          level: 1,
        });
        sendWsEvent(ws, WebSocketsClientEvents.TUTORIAL_HOUSE_BUILD, {
          house_type_name: name_saloon,
        });
        break;
      case 6:
        sendWsEvent(ws, WebSocketsClientEvents.COLLECT_RESOURCES_GOLD, {
          name: name_saloon,
          resources_amount_gold: 1800,
          resources_amount_pearl: 0,
        });
        break;
      case 7:
        sendWsEvent(ws, WebSocketsClientEvents.UPGRADE_HOUSE, {
          name: name_saloon,
          level: 2,
        });
        break;
      case 7:
        sendWsEvent(ws, WebSocketsClientEvents.UPGRADE_HOUSE, {
          name: name_dock,
          level: 1,
        });
        break;
      case 10:
        sendWsEvent(ws, WebSocketsClientEvents.SHIP_SENT_TRAVEL, {
          boost_used: false,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickAnywhere);

    return () => {
      document.removeEventListener("click", handleClickAnywhere);
    };
  }, []);

  return (
    <>
      {isActive && step >= 1 && (
        <div
          className={`${styles.tutorial__overlay} ${
            currentText && currentText.text2
              ? styles.tutorial__overlay__space__center
              : ""
          }`}
        >
          <div className={styles.tutorial__content}>
            <h2 className={styles.tutorial__title}>{currentText.title}</h2>
            <p className={styles.tutorial__text}>{currentText.text}</p>
            {currentText.text2 && (
              <p className={styles.tutorial__text}>{currentText.text2}</p>
            )}
          </div>
          {activeTutorial == TUTORIALS.FRIENDS && (
            <div className={styles.tutorial__image}>
              <Image width={96} height={96} src={ton} alt="image of ton" />
            </div>
          )}
          {currentText.isNeedButton && (
            <Button isDisabled={false} onClick={handleClickNextStep}>
              Далее
            </Button>
          )}
        </div>
      )}
      {isActive && (
        <div className={styles.tutorial__wrapper__btn_skip}>
          <Button isDisabled={false} onClick={handleClickSkipTutorial}>
            Пропустить
          </Button>
        </div>
      )}
    </>
  );
};

export default Tutorial;
