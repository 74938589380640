import React, { useEffect, useState } from "react";
import { ImodalModalProps } from "./interfaces";
import ActionButton from "../ActionButton";
import pearls from "@/assets/icons/pearls.png";
import coins from "@/assets/icons/coin.png";
import Image from "next/image";
import { formatCoin } from "@/utils/formatters/formatCoin";
import { RootState } from "@/store";
import { useDispatch, useSelector } from "react-redux";
import { POP_UP_TEXT } from "./popup.constants";
import close from "@/assets/icons/close.png";

import styles from "./styles.module.scss";
import useClickSound from "@/hooks/useClickSound";
import { handleSound, setLanguage } from "@/store/app/appSlice";
import { Language, LANGUAGES } from "@/constants/types";
import { setLocalStorageItem } from "@/utils/localStorage";
import { LANGUAGE } from "@/utils/location";

export default function Popup({
  isOpen,
  onClose,
  title,
  textButton,
  icon,
  values,
  text,
}: ImodalModalProps) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [enough, setEnough] = useState("");
  const formattedCoins = formatCoin(values?.values?.coins || 0);
  const formattedPears = formatCoin(values?.values?.pearls || 0);
  const playSound = useClickSound();
  const {isSoundDisabled,language} = useSelector(
    (state: RootState) => state.app
  );
  const dispatch = useDispatch();
  const { balance } = useSelector((state: RootState) => state.user);
  const settings = "НАСТРОЙКИ";
  const handleClose = () => {
    playSound();
    onClose(false);
  };

  useEffect(() => {
    if (!values) return;
    const isPrize = values?.textValue.includes(POP_UP_TEXT.PRIZE);

    if (isPrize) return;

    const hasEnoughCoins = balance?.coins >= values?.values?.coins;
    const hasEnoughPearls = balance?.pearls >= values?.values?.pearls;

    if (!hasEnoughCoins && !hasEnoughPearls) {
      setEnough("золота и жемчуга");
      setIsDisabled(true);
    } else {
      if (!hasEnoughCoins) {
        setEnough("золота");
        setIsDisabled(true);
      }
      if (!hasEnoughPearls) {
        setEnough("жемчуга");
        setIsDisabled(true);
      }
    }
  }, [values, balance.coins, balance.pearls]);

  if (!isOpen) return null;

  const handleClickYes = () => {
    onClose(true);
    playSound();
  };

  const handleClickSettingsSound = () => {
    dispatch(handleSound(!isSoundDisabled));
    playSound();
  };

  const handleClickChangeLanguage = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedLanguage = event.target.value as Language;
    dispatch(setLanguage(selectedLanguage));
    setLocalStorageItem(LANGUAGE, selectedLanguage);
    playSound();
  };

  if (title == settings)
    return (
      <div className={styles.container}>
        <div className={styles.modal}>
          <Image
            onClick={handleClose}
            className={styles.modal__close}
            src={close}
            alt="close"
          />
          <div className={styles.modal__title}>{title}</div>
          <div className={styles.modal__content}>
            <div className={styles.modal__actions__settings}>
              <div className={styles.modal__actions__settings__option}>
                <span>ЗВУК</span>
                <button
                  onClick={handleClickSettingsSound}
                  className={`${styles.modal__btn} ${
                    isSoundDisabled && styles.modal__btn_disabled
                  }`}
                >
                  {isSoundDisabled ? "ВЫКЛ" : "ВКЛ"}
                </button>
              </div>
              <div className={styles.modal__actions__settings__option}>
                <span>ЯЗЫК</span>
                <select
                  onChange={handleClickChangeLanguage}
                  defaultValue={language}
                >
                  {Object.keys(LANGUAGES).map((lang) => (
                    <option key={lang} value={lang}>
                      {lang}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <Image
          onClick={handleClose}
          className={styles.modal__close}
          src={close}
          alt="close"
        />
        <div className={styles.modal__title}>{title}</div>
        <div className={styles.modal__content}>
          {icon && (
            <Image
              src={icon}
              alt="icon"
              width={title == "ПОКУПКА" ? 128 : 32}
            />
          )}
          {text && <h2 className={styles.modal__text}>{text}</h2>}
          {values && (
            <div className={styles.modal__value}>
              {values.values.coins > 0 ||
                (values.values.pearls > 0 && (
                  <span className={styles.modal__value__text}>
                    {values.textValue}
                  </span>
                ))}

              <div className={styles.modal__value__items}>
                {values.values.pearls > 0 && (
                  <div className={styles.modal__value__item}>
                    <Image width={32} height={32} src={pearls} alt="pearls" />
                    <span>{formattedPears}</span>
                  </div>
                )}
                {values.values.coins > 0 && (
                  <div className={styles.modal__value__item}>
                    <Image width={32} height={32} src={coins} alt="pearls" />
                    <span>{formattedCoins}</span>
                  </div>
                )}
              </div>
            </div>
          )}
          {isDisabled && enough && (
            <span className={styles.modal__text__error}>
              Не хватает ресурсов!
            </span>
          )}
          <div className={styles.modal__actions}>
            <ActionButton onClick={handleClickYes} isDisabled={isDisabled}>
              {textButton}
            </ActionButton>
          </div>
        </div>
      </div>
    </div>
  );
}
