interface Translations {
  [key: string]: {
    [lang: string]: string;
  };
}

const translations: Translations = {
  БУСТЕРЫ: {
    ru: "БУСТЕРЫ",
    en: "BUSTERS",
  },
  ЖЕМЧУГ: {
    ru: "ЖЕМЧУГ",
    en: "PEARLS",
  },
  "2х БУСТЕР НА 12 ЧАСОВ": {
    ru: "2х БУСТЕР НА 12 ЧАСОВ",
    en: "2X BOOSTERS FOR 12 HOURS",
  },
  "2Х БУСТЕР НА 9 ЧАСОВ": {
    ru: "2Х БУСТЕР НА 9 ЧАСОВ",
    en: "2X BOOSTERS FOR 9 HOURS",
  },
  "2Х БУСТЕР НА 6 ЧАСОВ": {
    ru: "2Х БУСТЕР НА 6 ЧАСОВ",
    en: "2X BOOSTERS FOR 6 HOURS",
  },
  "2Х БУСТЕР НА 3 ЧАСА": {
    ru: "2Х БУСТЕР НА 3 ЧАСАВ",
    en: "2X BOOSTERS FOR 3 HOURS",
  },
  "20 БУСТЕРОВ АВТОСБОРА": {
    ru: "20 БУСТЕРА АВТОСБОРА",
    en: "20 BOOSTERS AUTOCOLLECT",
  },
  "15 БУСТЕРОВ АВТОСБОРА": {
    ru: "15 БУСТЕРА АВТОСБОРА",
    en: "15 BOOSTERS AUTOCOLLECT",
  },
  "9 БУСТЕРОВ АВТОСБОРА": {
    ru: "9 БУСТЕРА АВТОСБОРА",
    en: "9 BOOSTERS AUTOCOLLECT",
  },
  "3 БУСТЕРОВ АВТОСБОРА": {
    ru: "3 БУСТЕРА АВТОСБОРА",
    en: "3 BOOSTERS AUTOCOLLECT",
  },
  "1200 ЖЕМЧУЖИН":{
    ru: "1200 ЖЕМЧУЖИН",
    en: "1200 PEARLS",
  },
  "3999 ЖЕМЧУЖИН":{
    ru: "3999 ЖЕМЧУЖИН",
    en: "3999 PEARLS",
  },
  "8999 ЖЕМЧУЖИН":{
    ru: "8999 ЖЕМЧУЖИН",
    en: "8999 PEARLS",
  },
  "15999 ЖЕМЧУЖИН":{
    ru: "15999 ЖЕМЧУЖИН",
    en: "15999 PEARLS",
  }
};

export const LANGUAGE = "Language";

export const getTranslation = (key: string, lang: string): string => {
  return translations[key]?.[lang] || key;
};
