import avatar from "@/assets/hud/header/avatar.png";

export const USER = {
  wallet: null,
  id: 1,
  url: avatar.src,
  balance: {
    ton: 999999,
    coins: 9999999,
    pearls: 999999,
    multipliers_boosters: {
      booster: "X5",
      time_remaing: 1000,
      boosterID: null,
    },
    auto_boosters: {
      quantity: 10,
    },
    keys_cheast: 0,
    lastOpenedChestIndex: null,
  },
  chests: [],
  username: "User",
  ref: 0,
  is_premium: false,
  stats: {
    count_ref_lvl1: 1,
    count_ref_lvl2: 1,
    earned_from_lvl1: 1,
    earned_from_lvl2: 1,
    rewards_pearl: {
      earned_pearls: 1,
    },
    rewards_ton: {
      earned_ton: 1,
    },
    total_gold: 0,
    total_pearls: 0,
  },
  firstExperience: null,
};
