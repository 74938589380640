import { IHouse, IProcesedHouse } from "@/store/island/interfaces";
import { houseCoordinates } from "@/constants/buildings";
import { generateImagePath } from "./generateImagePath";
import {
  clearTimer,
  decreaseAutoBoosters,
  decreaseTimeRemaingToCollect,
  resetTimeRemaining,
  selectHouseByName,
  setCanCollect,
  setTimer,
} from "@/store/island/islandSlice";
import { Dispatch } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "@/store";
import { sendWsEvent } from "../ws";
import { WebSocketsClientEvents } from "@/constants/events";
import { USER } from "@/store/user/user.mockData";
import {
  decreaseBoosterTime,
  resetBooster,
  setBoosterID,
} from "@/store/user/userSlice";
import { IUser } from "@/interfaces/DTO";
import { IBoostersDTO } from "@/store/user/interfaces";
import { HOUSES } from "@/store/island/island.mockData";
import { LOCAL_STORAGE } from "@/constants/localStorage";
import { setLocalStorageItem } from "../localStorage";

export const processData = (
  houses: IHouse[] | IProcesedHouse[]
): IProcesedHouse[] => {
  return houses.map((house) => {
    const images = {
      default: generateImagePath(house.house_type_name, house.level, "default"),
      select: generateImagePath(house.house_type_name, house.level, "select"),
    };

    const coordinates = houseCoordinates[house.house_type_name]?.[
      house.level
    ] || { x: 0, y: 0 };

    const time_remaining_to_full_resources =
      typeof house?.time_remaining_to_full_resources == "string"
        ? convertTimeStamp(house?.time_remaining_to_full_resources)
        : house?.time_remaining_to_full_resources;

    return {
      ...house,
      time_remaining_to_full_resources,
      coordinates,
      images: images,
    };
  });
};

export const convertTimeStamp = (time: string): number => {
  const currentTime = Date.now();
  const normalizedTime = time.split(".")[0] + "Z";

  const targetTime = new Date(normalizedTime).getTime();

  if (isNaN(targetTime)) {
    return 0;
  }

  const remainingTimeMs = targetTime - currentTime;
  const remainingTimeSeconds = Math.floor(remainingTimeMs / 1000);

  return remainingTimeSeconds > 0 ? remainingTimeSeconds + 1 : 0;
};

export const startHouseTimers =
  (houses: IProcesedHouse[], ws: WebSocket) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    houses.forEach((house) => {
      const interval = setInterval(() => {
        const updatedHouse = selectHouseByName(
          getState(),
          house.house_type_name
        );

        if (updatedHouse) {
          if (updatedHouse.time_remaining_to_full_resources > 0) {
            if (updatedHouse.time_remaining_to_full_resources == 1) {
              setTimeout(() => {
                sendWsEvent(ws, WebSocketsClientEvents.ACUTALIZED_DATA, {});
              }, 1050);
            }

            dispatch(
              decreaseTimeRemaingToCollect({
                name: updatedHouse.house_type_name,
              })
            );
          } else {
            dispatch(clearTimer(updatedHouse.house_type_name));
          }
        }
      }, 1000);

      dispatch(setTimer({ name: house.house_type_name, timerId: interval }));
    });
  };

export const startBoosterTimerByUser =
  (user: IUser, ws: WebSocket) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    if (!user.balance.multipliers_boosters) {
      user.balance.multipliers_boosters = {
        booster: "X5",
        time_remaing: 1550,
      };
    }
    if (user.balance.multipliers_boosters.time_remaing > 0) {
      const boosterInterval = setInterval(() => {
        const updatedUser = getState().user;
        const remainingTime =
          updatedUser.balance.multipliers_boosters.time_remaing;

        if (remainingTime > 0) {
          dispatch(
            decreaseBoosterTime({
              time_remaing: remainingTime - 1,
            })
          );
        } else {
          dispatch(
            resetBooster({
              booster: null,
            })
          );

          clearInterval(boosterInterval);
        }
      }, 1000);
    }
  };

  export const startBoosterTimer =
  (boosters: IBoostersDTO, ws: WebSocket) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    
    const boosterIntervalID =
      getState().user.balance.multipliers_boosters.boosterID;
      
    if (boosterIntervalID) {
      clearInterval(boosterIntervalID as unknown as number);
      dispatch(setBoosterID(null));
    }

    if (boosters.multipliers_boosters.time_remaing > 0) {
      const boosterInterval = setInterval(() => {
        const updatedBoosters = getState().user.balance.multipliers_boosters;
        const remainingTime = updatedBoosters.time_remaing;

        if (remainingTime > 1) {  
          dispatch(
            decreaseBoosterTime({
              time_remaing: remainingTime - 1, 
            })
          );
        } else {
          dispatch(
            resetBooster({
              booster: null,
            })
          );

          clearInterval(boosterInterval);

          // sendWsEvent(ws, WebSocketsClientEvents.BOOSTER_EXPIRED, {
          //   booster: updatedBoosters.booster,
          // });
        }
      }, 1000);
      dispatch(setBoosterID(boosterInterval));
    }
  };

